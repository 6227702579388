import { CountUp } from "countup.js";
import { load as ReCaptchaLoad } from 'recaptcha-v3';
import { WebpMachine } from "webp-hero";
import $ from "jquery";
import { config } from "../data/global.yaml";

var process; // need to define to access global provided by Webpack

$(async () => {
    // On DOM ready

    // Detect WebP support and polyfill to PNG if unsupported
    const webpMachine = new WebpMachine({
        webpSupport: undefined,  //set false to force update
        useCanvasElements: false,
    });
    webpMachine.polyfillDocument();

    // toggle hamburger menu on mobile
    $('.nav-item--icon').on('click', function() {
        $('.main-nav').toggleClass('responsive');
    });

    // close hamburger menu if nav item clicked
    $('.nav-item--link').on('click', function() {
        $('.main-nav').removeClass('responsive');
    });

    // faq accordion
    $('.faq-accordion > .faq-accordion--button').on('click', function() {
        $(this).next('.faq-accordion--contents').slideToggle();
        $(this).toggleClass('active');
    });
      
    // co2 counter
    const co2Count = new CountUp('co2Counter', 41000, {
        // start animation when target is in view
        duration: 2,
        enableScrollSpy: true,
        scrollSpyOnce: true,
        scrollSpyDelay: 300
    });

    // print current year
    $('#year').text( (new Date).getFullYear());

    // service areas tabs
    // add inactive class to all except for first
    $('.tab-header--link:not(:first)').addClass('inactive');

    // hide tab content by default except for first tab
    $('.tab-body--content').hide();
    $('.tab-body--content:first').show();

    $('.tab-header--link').on('click', function() {
        let tabIndex = $(this).attr('id');

        if($(this).hasClass('inactive')) {
            $('.tab-header--link').addClass('inactive');
            $(this).removeClass('inactive');
            
            // hide body content
            $('.tab-body--content').hide();
            $('#tab' + tabIndex + 'Content').fadeIn('fast');
        }
    });

    // randomize recent shipments

    // hide all shipment items first
    $('.shipment-item').hide();
    // randomize shipment
    randomShipment();

    function randomShipment() {
        for (let i = 0; i < 3; i++) {
            let shipmentItems = $('.shipment-item:not(:visible)').length;
            let random = Math.floor(Math.random() * shipmentItems);
            $('.shipment-item:not(:visible)').eq(random).show();
        }
    }
    // Load Google reCAPTCHA v3
    const siteKey = process.env.ReCaptchaSiteKey || config.recaptcha.siteKey;
    const recaptcha = await ReCaptchaLoad(siteKey);

    // Form API Endpoint
    const formEndpoint = process.env.FormEndpoint || config.form.endpoint;
    // console.log('endpoint', formEndpoint);

    // contact form
    $('#zipcode-form').on('submit', function(e) {
        e.preventDefault();
        console.log('zipcode submit event');
        $('#zipcode-form button').text('Sending').prop("disabled", true);
        
        recaptcha.execute('zipcodeForm').then((token) => {
            const data = $('#zipcode-form')
                .serializeArray()
                .reduce((obj, { name, value }) => {
                    obj[name] = value;
                    return obj;
                }, {});
            console.log('data', data); //DEBUG
            data['_gReCaptchaToken'] = token;
            $.ajax({
                url: formEndpoint,
                crossDomain: true,
                type: "POST",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(data),
            }).done(result => {
                console.log(result); //DEBUG
                $('#zipcode-form').append('<div class="message success">Your request has been sent.</div>');
            }).fail((xhr, resp, text) => {
                console.log(xhr, resp, text); //DEBUG
                $('#zipcode-form').append('<div class="message error">There was an error processing your request.</div>');
            }).always(() => {
                $('#zipcode-form button').text('Submit').prop("disabled", false);
                setTimeout(() => {
                    $('#zipcode-form .message').fadeOut(1000, function() { $( this ).remove(); });
                }, 4000);
            });
        });
    });

    // contact form
    $('#contact-form').on('submit', function(e) {
        e.preventDefault();
        console.log('contact submit event'); //DEBUG
        $('#contact-form button').text('Sending').prop("disabled", true);
        
        recaptcha.execute('contactForm').then((token) => {
            const data = $('#contact-form')
                .serializeArray()
                .reduce((obj, { name, value }) => {
                    obj[name] = value;
                    return obj;
                }, {});
            console.log('data', data); //DEBUG
            data['_gReCaptchaToken'] = token;
            $.ajax({
                url: formEndpoint,
                crossDomain: true,
                type: "POST",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(data),
            }).done(result => {
                console.log(result); //DEBUG
                $('#contact-form').prepend('<div class="message success">Your request has been sent.</div>');
            }).fail((xhr, resp, text) => {
                console.log(xhr, resp, text); //DEBUG
                $('#contact-form').prepend('<div class="message error">There was an error processing your request.</div>');
            }).always(() => {
                $('#contact-form button').text('Submit').prop("disabled", false);
                setTimeout(() => {
                    $('#contact-form .message').fadeOut(1000, function() { $( this ).remove(); });
                }, 4000);
            });
        });
    });

    // will it ship

    var ttl_correct = 0;
    var curr_question = 0;
    var curr_answer;

    $('.btn-next').on('click',function() {
        var next = ".q-" + $(this).data('next');
        $(this).parents('.willitship-q').removeClass('active');
        $(next).addClass('active');
        $('.item').addClass('active-item');
        curr_question++;
        if(curr_question == '11') {
            $('.q-11 span').text(ttl_correct);
        }
    }); 

    $('.answer').on('click',function() {
        if(!$(this).hasClass('disabled')) {
            var next = ".q-" + $(this).data('next');
            $(this).parents('.willitship-q').removeClass('active');
            $(next).addClass('active');
            $('.item').removeClass('active-item');
            curr_answer = $(this).data('value');
            console.log('current answer is', curr_answer);
            if(curr_answer == "1") {
                $(next + " .right").addClass('active');
            } else {
                $(next + " .wrong").addClass('active');
            }
        }
    });


});




